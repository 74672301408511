import React, { useRef, useEffect } from "react"
import Layout from "../components/layout"

import * as d3 from 'd3';

import Card from "../components/card"

const data = [1.5, 2.5, 3.5, 4.5, 6.0];

const D3Foo = (props) => {
   const container = useRef(null)

   useEffect(() => {
      if (container.current) {
         const svg = d3.select(container.current)
          .append(`svg`)
          .attr(`width`, `100%`)
          .attr(`height`, `50vh`)

         svg.selectAll('circle')
          .data(data)
          .enter().append('circle')
          .style('fill', 'red')
          .attr('cx', (d,i) => i*100 + 30 )
          .attr('cy', (d,i) => i*100 + 30 )
          .attr('r', (d) => Math.sqrt(d) * 3)

         const circle = svg.selectAll('circle')
         circle.exit().remove()
      }
   }, [container.current])

   return (
      <Card>
         <svg
          classname="d3-component"
          width={`100%`}
          height={`50vh`}
          ref={container}
          />
      </Card>
   );
}

export default () => (
	<Layout>
      <D3Foo />
	</Layout>
)
